<template>
    <div class="modal secondary faq_modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                  <h1 class="m-0">{{ isEdit ? 'Edit' : 'Add' }} FAQ</h1>
                  <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-faq" class="playbook-faq">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">FAQ Question</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.question }">
                                    <Field autocomplete="off" name="question" type="text" placeholder="ex: What do you do as a fitness coach?" v-model="form.question" rules="required" />
                                </div>
                                <ErrorMessage name="question" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">FAQ Answer</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.answer }">
                                    <Field autocomplete="off" type="textarea" name="answer" v-model="form.answer" rules="required">
                                        <redactor v-model="form.answer" name="answer" :default-value="question.answer" :reset-watcher="resetWatcher" />
                                    </Field>
                                </div>
                                <ErrorMessage name="answer" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="playbookUpdateLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="playbookUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const Redactor = defineAsyncComponent(() => import('@/components/Redactor'))

    export default {
        name: 'Playbook Offer Headline',

        data () {
            return {
                form: {
                    playbook_id: 0,
                    question: '',
                    answer: '',
                },
                resetWatcher: 0,
            };
        },

        props: {
            modelValue: Boolean,
            isEdit: {
                type: Boolean,
                default: false,
            },
            question: {
                type: Object,
                default: () => {},
            }
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            Redactor,
        },

        watch: {
            question () {
                const vm = this;

                vm.resetForm();
            },

            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                }
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                savePlaybookFAQ: 'playbookModule/savePlaybookFAQ',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                if (vm.isEdit) {
                    vm.form = {
                        id: vm.question.id,
                        playbook_id: vm.question.playbook_id,
                        question: vm.question.question,
                        answer: vm.question.answer,
                    };
                } else {
                    vm.form = {
                        playbook_id: vm.selectedPlaybook.id,
                        question: '',
                        answer: '',
                    };
                }

                setTimeout(function () {
                    vm.resetWatcher += 1;
                }, 10);
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;

                vm.savePlaybookFAQ(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .modal.faq_modal .modal_container {
        padding: 0;
    }

    .modal.faq_modal .modal_body{
        width: 100%;
        height: 450px;
    }
    .modal.faq_modal .modal_footer {
        width: 100%;
    }
    .modal.faq_modal .modal_header{
        padding: 15px 40px;
        border-radius: 12px 12px 0 0;
        width: 100%;
    }
    .modal.faq_modal .modal_body{
        padding: 15px 40px 20px 40px;
        width: 100%;
    }
    .modal.faq_modal .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .playbook-faq {
        width: 100%;
    }
</style>